import './Projects.scss';

import { Title } from "../components/Title";
import Blob6 from '../assets/blob-6.svg';
import { Project } from '../components/Project';
import Vindigo from '../assets/vindigo.png';
import CommentAnchors from '../assets/comment-anchors.png';
import Meltdown from '../assets/meltdown.png';

export function Projects() {
	return (
		<section className="segment projects">
			<img src={Blob6} alt="" className="blob blob--6" />

			<div className="container">
				<Title subtitle="My proudest works!">
					Notable projects.
				</Title>
				<p>
					Making new projects is one of the most fun parts of the job, especially when these
					projects turn into something special. Here is an overview of some of my favorite projects.
				</p>
				<div className="projects__list">
					<Project
						image={Vindigo}
						title="Vindigo"
						subtitle="Open-Source task planner"
						info="A simple but effective task planner focused on productivity and ease of use. Fully free and open source for all to use!"
						href="https://github.com/StarlaneStudios/vindigo"
					/>
					<div className="projects__divider" />
					<Project
						image={CommentAnchors}
						title="Comment Anchors"
						subtitle="Open-Source VSCode Extension"
						info="A VSCode editor extension which allows for easy bookmarking and navigating through your source code."
						href="https://marketplace.visualstudio.com/items?itemName=ExodiusStudios.comment-anchors"
					/>
					<div className="projects__divider" />
					<Project
						image={Meltdown}
						title="Meltdown"
						subtitle="Game Jam entry (Video Game)"
						info="An isometric 3D puzzle game in which you must repair a malfunctioning nuclear reactor by repairing all broken pipes."
						href="https://starlane-studios.itch.io/meltdown"
					/>
				</div>
			</div>
		</section>
	);
}