import { Button } from './Button';
import './Project.scss';

export interface Props {
	image: string;
	title: string;
	subtitle: string;
	info: string;
	href: string;
}

export function Project(props: Props) {
	return (
		<div className="project">
			<img src={props.image} alt={props.title} />
			<h1>
				{props.title}
			</h1>
			<h2>
				{props.subtitle}
			</h2>
			<p>
				{props.info}
			</p>
			<Button link={props.href}>
				Learn more
			</Button>
		</div>
	);
}