import './Skills.scss';

import { Title } from '../components/Title';
import Blob5 from '../assets/blob-5.svg';
import { Skill } from '../components/Skill';
import { Favorite } from '../components/Favorite';
import React from '../assets/react.png';
import Kotlin from '../assets/kotlin.png';
import Godot from '../assets/godot.png';

export function Skills() {
	return (
		<section className="segment skills">
			<img src={Blob5} alt="" className="blob blob--5" />

			<div className="container">
				<Title subtitle="Including but not limited to..." no-line>
					My skills.
				</Title>
				<div className="skills__list">
					<Skill
						name="Frontend Development"
						subtitle="React, Vue, Sass."
						value={100}
					/>
					<Skill
						name="Backend Development"
						subtitle="Node.js, Java, GraphQL, SQL."
						value={100}
					/>
					<Skill
						name="Desktop App Developnent"
						subtitle="Electron, Kotlin, .NET/C#"
						value={90}
					/>
					<Skill
						name="Graphic Design"
						subtitle="Vector graphics, web design."
						value={80}
					/>
					<Skill
						name="Game Development"
						subtitle="Godot, Unity."
						value={80}
					/>
					<Skill
						name="Mobile App Development"
						subtitle="Android, iOS"
						value={70}
					/>
				</div>
				<div className="skills__fav-subtitle">
					And these are	
				</div>
				<div className="skills__fav-title">
					My favorites.	
				</div>
				<div className="skills__favorites">
					<Favorite
						title="React.js"
						subtitle="Frontend Development"
						image={React}
					/>
					<Favorite
						title="Kotlin"
						subtitle="Backend/Server Development"
						image={Kotlin}
					/>
					<Favorite
						title="Godot"
						subtitle="Game Development"
						image={Godot}
					/>
				</div>
			</div>
		</section>
	);
}