import { CSSProperties, useEffect, useState } from 'react';
import './Skill.scss';
import { Spacer } from './Spacer';

export interface Props {
	name: string;
	subtitle: string;
	value: number;
}

export function Skill({ name, subtitle, value }: Props) {
	const [ width, setWidth ] = useState(0);

	useEffect(() => {
		setWidth(value);
	}, [ value ]);

	const style: CSSProperties = {
		width: `${width}%`
	}

	return (
		<div className="skill">
			<div className="skill__details">
				<div>
					<div className="skill__name">
						{name}
					</div>
					<div className="skill__subtitle">
						{subtitle}
					</div>
				</div>
				<Spacer />
				<div className="skill__value">
					{value}%
				</div>
			</div>
			<div className="skill__bar">
				<div
					className="skill__bar-inner"
					style={style}
				/>
			</div>
		</div>
	)
}