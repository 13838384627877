import './Toolbar.scss';

import { default as Logo } from '../assets/brand.svg';
import { Spacer } from './Spacer';
import classNames from 'classnames';

export interface Props {
	floating: boolean;
	current: number;
	setCurrent: (current: number) => void;
}

export function Toolbar({ floating, current, setCurrent }: Props) {
	const items = [
		{ name: 'Home', target: 'landing' },
		{ name: 'About', target: 'about-me' },
		{ name: 'Skills', target: 'skills' },
		{ name: 'Projects', target: 'projects' },
		{ name: 'Contact', target: 'contact' },
	]

	const navigation = items.map(({name, target}, i) => (
		<li
			key={target}
			role="button"
			onClick={() => scrollTo(target, i)}
			className={classNames({
				'active': i === current,
			})}
		>
			{ name }
		</li>
	));

	function scrollTo(id: string, idx: number) {
		const element = document.getElementById(id);

		element?.scrollIntoView({
			block: 'start',
			behavior: 'smooth'
		});

		setCurrent(idx);
	}

	const classes = classNames({
		'toolbar': true,
		'toolbar--floating': floating
	});

	return (
		<nav className={classes}>
			<div className="container">
				<img
					src={Logo}
					alt="Julian Mills"
				/>
				<Spacer />
				<ol>
					{ navigation }
				</ol>
			</div>
		</nav>
	);
}