import './Title.scss';

export interface Props {
	children: React.ReactNode;
	subtitle?: string;
	'no-line'?: boolean;
}

export function Title({ children, subtitle, 'no-line': noLine }: Props) {
	return (
		<div className="title">
			<h1 className="title">
				{children}
			</h1>
			{subtitle && (
				<h2>
					{subtitle}
				</h2>
			)}
			{!noLine && (
				<div className="title__line" />
			)}
		</div>
	);
}