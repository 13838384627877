import './Landing.scss';

import Blob1 from '../assets/blob-1.svg';
import Blob2 from '../assets/blob-2.svg';
import { Title } from '../components/Title';
import Profile from '../assets/profile.png';
import { Button } from '../components/Button';

export interface Props {
	setCurrent: (current: number) => void;
}

export function Landing({ setCurrent }: Props) {
	return (
		<section className="segment landing">
			<img src={Blob1} alt="" className="blob blob--1" />
			<img src={Blob2} alt="" className="blob blob--2" />

			<div className="container">
				<div>
					<Title>
						Hey, <br/>
						I'm Julian.
					</Title>
					<p>
						As a Full-stack Web UI/UX Designer and Developer with over 5 years hands-on
						experience I design and craft fully responsive interactive websites and applications.
					</p>
					<div className="landing__actions">
						<Button onClick={() => setCurrent(1)}>
							More about me
						</Button>
						<Button onClick={() => setCurrent(4)} text>
							Contact me
						</Button>
					</div>
				</div>
				<div>
					<img
						className="landing__profile"
						src={Profile}
						alt="Me"
					/>
				</div>
			</div>
		</section>
	);
}