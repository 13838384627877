import './Favorite.scss';

export interface Props {
	image: any;
	title: string;
	subtitle: string;
}

export function Favorite({ image, title, subtitle }: Props) {
	return (
		<div className="favorite">
			<img src={image} alt={title} className="favorite__image" />
			<div className="favorite__title">
				{title}
			</div>
			<div className="favorite__subtitle">
				{subtitle}
			</div>
		</div>
	);
}