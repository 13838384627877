import Icon from "@mdi/react";
import { AboutMe } from "./pages/AboutMe";
import { Contact } from "./pages/Contact";
import { Skills } from "./pages/Skills";
import { Landing } from "./pages/Landing";
import { Projects } from "./pages/Projects";
import { Toolbar } from "./components/Toolbar";
import { useEffect, useRef, useState } from 'react';
import { Spacer } from "./components/Spacer";
import { mdiBrush } from "@mdi/js";

export function App() {
	const [ segment, setSegment ] = useState(0);
	const [ floating, setFloating ] = useState(false);

	const mainRef = useRef<HTMLElement|null>(null);
	const segments = useRef<HTMLElement[]>([]);

	// Listen to scroll events
	useEffect(() => {
		segments.current = Array.from(mainRef.current?.childNodes || []) as HTMLElement[];

		function checkFloating() {
			setFloating(window.scrollY > 0);
		}
		
		function onScroll(e: Event) {
			checkFloating();

			for(const segment of segments.current) {
				if(segment.offsetTop === window.scrollY) {
					const idx = segments.current.indexOf(segment);

					setSegment(idx);
					break;
				}
			}
		}

		window.addEventListener('scroll', onScroll);

		checkFloating();

		return () => {
			window.removeEventListener('scroll', onScroll);
		}
	}, []);

	// Scroll to the active segment
	useEffect(() => {
		const target = segments.current![segment]! as HTMLElement;

		target?.scrollIntoView({
			block: 'start',
			behavior: 'smooth'
		})
	}, [ segment ]);

	return (
		<div className="site">
			<Toolbar
				floating={floating}
				current={segment}
				setCurrent={setSegment}
			/>

			<main ref={mainRef}>
				<Landing setCurrent={setSegment} />
				<AboutMe />
				<Skills />
				<Projects />
				<Contact />
			</main>

			<div className="container footer">
				Copyright &copy; Julian Mills 2022 &mdash; All Rights Reserved
				<Spacer />
				Designed by myself
				<Icon
					style={{ marginLeft: 4 }}
					path={mdiBrush}
					size={0.8}
				/>
			</div>
		</div>
	);
}