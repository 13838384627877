import './Contact.scss';

import { mdiDiscord, mdiEmail, mdiGithub, mdiLinkedin, mdiTwitter } from "@mdi/js";
import Icon from "@mdi/react";
import { Title } from "../components/Title";
import Blob7 from '../assets/blob-7.svg';

export function Contact() {
	return (
		<section className="segment contact">
			<img src={Blob7} alt="" className="blob blob--7" />

			<div className="container">
				<Title subtitle="For serious inquiries only!">
					Contact me.
				</Title>
				<p>
					You can find me on various platforms. Feel free to reach out for questions or offers!
				</p>
				<ul>
					<li>
						<Icon
							path={mdiEmail}
							size={1}
						/>
						me<span>(at)</span>jmills.nl
					</li>
					<li>
						<Icon
							path={mdiTwitter}
							size={1}
						/>
						<a href="https://twitter.com/heyimjools">
							@heyImJools
						</a>
					</li>
					<li>
						<Icon
							path={mdiDiscord}
							size={1}
						/>
						Jøøls#4778
					</li>
					<li>
						<Icon
							path={mdiLinkedin}
							size={1}
						/>
						<a href="https://www.linkedin.com/in/julian-mills-b0814a166/">
							Julian Mills
						</a>
					</li>
					<li>
						<Icon
							path={mdiGithub}
							size={1}
						/>
						<a href="https://github.com/macjuul">
							macjuul
						</a>
					</li>
				</ul>
			</div>
		</section>
	);
}