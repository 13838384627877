import "./AboutMe.scss";

import { Title } from "../components/Title";
import Timeline from '../assets/timeline.png';
import Blob3 from '../assets/blob-3.svg';
import Blob4 from '../assets/blob-4.svg';

export function AboutMe() {
	return (
		<section className="segment about-me">
			<img src={Blob3} alt="" className="blob blob--3" />
			<img src={Blob4} alt="" className="blob blob--4" />

			<div className="container">
				<div className="about-me__info">
					<Title subtitle="So, who exactly am I?">
						About me.
					</Title>
					<p>
						I'm a Dutch software developer with a passion for making visually pleasing user interfaces.
						I self taught myself many skills including  full-stack web development, game development and
						linux system administration. I've released multiple open source projects on GitHub ranging
						from development tools to planning applications.
					</p>
					<p>
						When I'm not at work I usually spend my time tinkering around with small
						projects 💻, playing video games 🎮, and listening to music 🎧.
					</p>
					<p>
						<strong>
							I'm currently employed as web developer at Yaacomm.
						</strong>
					</p>
				</div>
				<div>
					<img
						className="about-me__timeline"
						src={Timeline}
						alt="Timeline"
					/>
				</div>
			</div>
		</section>
	);
}