import classNames from 'classnames';
import './Button.scss';

export interface Props {
	children?: React.ReactNode;
	link?: string;
	text?: boolean;
	onClick?: (e: Event) => void;
}

export function Button({ children, link, text, onClick }: Props) {
	const Tag = link ? 'a' : 'button';
	const props: any = {};

	if(link) {
		props.href = link;
		props.target = '_blank';
	}

	const classes = classNames({
		'button': true,
		'button--text': text
	});

	return (
		<Tag {...props} className={classes} onClick={onClick}>
			{children}
		</Tag>
	)
}